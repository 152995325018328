const routes = {
  mainPage: '/',
  registration: '/registration',
  login: "/login",
  videok: "/videos/:id",
  video: "/video/:id",
  tutoringApplication: "/apply"
};

export default routes;
